/*======================================
    Colors
======================================*/

// Primary colors

$green-light: rgb(140, 194, 71);    //#8cc247
$gray: rgb(119, 119, 119);          //#777
$gray-med: rgb(164, 164, 164);      //$a4a4a4
$gray-light: rgb(244, 244, 244);    //#f4f4f4
$orange: rgb(255, 161, 0);          //#ffa100
$cream: rgb(252, 248, 232);         //#fcf8e8
$black: rgb(0, 0, 0);               //#000
$brown: rgb(86, 38, 0);             //#562600
$white: rgb(255, 255, 255);         //#fff


// Secondary colors

$green-dark: rgb(59, 106, 54);      //#3b6a36
$blue-light: rgb(59, 177, 225);     //#3bb1e1
$blue: rgb(0, 80, 130);             //#005082
$pink: rgb(249, 125, 130);          //#f97d82
$red: rgb(168, 11, 17);             //#a80b11
$red-orange: rgb(239, 86, 29);      //#ef561d
$purple: rgb(138, 116, 178);        //#8a74b2


$colors: (
    'green-light': $green-light,
    'orange': $orange,
    'brown': $brown,
    'green-dark': $green-dark,
    'blue-light': $blue-light,
    'blue': $blue,
    'pink': $pink,
    'red': $red,
    'red-orange': $red-orange,
    'purple': $purple
);


/*======================================
    Fonts
======================================*/

$sans: 'freight-sans-pro', sans-serif;
$serif: 'ff-tisa-web-pro', serif;


/*======================================
    Breakpoints (defined in ems)
======================================*/

$breakpoints: (
    'sm-mobile': 20em,        // 320px
    'mobile': 23.4375em,      // 375px
    'lg-mobile': 26em,        // 416px
    'phablet': 37.5em,        // 600px
    'sm-tablet': 46em,        // 736px
    'tablet': 47.9375em,      // 767px
    'tablet-vert': 48em,      // 768px
    'lg-tablet': 50em,        // 800px
    'xs-desktop': 57.5em,     // 920px
    'sm-desktop': 62.5em,     // 1000px
    'desktop': 75em,          // 1200px
    'md-desktop': 81.25em,    // 1300px
    'lg-desktop': 100em       // 1600px
);
