@import 'partials/variables';

body {
    background: rgba($green-light, 0.5);
}

.login h1 a {
    background-image: url('../images/icon-fpc-white.svg');
    background-size: contain;
    height: 120px;
    width: 120px;
}
